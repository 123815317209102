//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'ErrorPage',
  props: {
    error: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  head: {
    title: '404',
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: 'Page not found'
      }
    ]
  }
}
