export default () => ({
  tree: [{
    uuid: '',
    slug: '',
    title: '',
    children: []
  }],
  current: {
    article: {},
    breadcrumb: []
  },
  searchResult: {
    data: [],
    pagination: {}
  }
})
