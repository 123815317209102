export default {
  setTree (state, payload) {
    state.tree = payload
  },
  setCurrent (state, payload) {
    state.current = payload
  },
  setBreadCrumbCurrent (state, payload) {
    state.current.breadcrumb = payload
  },
  setSearchResult (state, payload) {
    state.searchResult = payload
  }
}
