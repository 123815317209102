//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TheFooter',
  props: {
    propKbName: {
      type: String,
      default: 'Kb Name'
    }
  },
  computed: {
    kb () {
      return this.$store.state.kb.data
    }
  }
}
