//
//
//
//
//
//
//
//
//

export default {
  props: {
    propText: {
      type: String,
      default: ''
    }
  },

  methods: {
    clicked () {
      this.$emit('click')
    }
  }
}
