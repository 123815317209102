//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BaseInputWithIcon from '~/components/base/BaseInputWithIcon.vue'
export default {
  name: 'TheHeader',
  components: {
    BaseInputWithIcon
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['propHeaderBackgroundColor', 'propSearchFieldBackgroundColor'],
  computed: {
    kb () {
      return this.$store.state.kb.data
    }
  },
  methods: {
    search (article) {
      this.$emit('click-search', article)
    }
  }
}
