export default {
  getKb ({ commit }, kb) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`kb/${kb}`).then((response) => {
        if (response) {
          commit('kb/setData', response.data.data, { root: true })
          resolve(response.data.data)
        }
      }, (error) => {
        const response = error.response.data
        reject(response)
      })
    })
  },
  getTree ({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      this.$axios.get(`kb/${rootState.kb.data.slug}/articles-tree`).then((response) => {
        if (response) {
          commit('articles/setTree', response.data.data, { root: true })
          resolve(response.data.data)
        }
      }, (error) => {
        const response = error.response.data
        reject(response)
      })
    })
  },
  searchArticle ({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`kb/${rootState.kb.data.slug}/search`, { params: payload })
        .then((response) => {
          commit('articles/setSearchResult', response.data.data, { root: true })
          commit('articles/setBreadCrumbCurrent', [], { root: true })
          resolve(response.data.data)
        }, (error) => {
          const response = error.response.data
          reject(response)
        })
    })
  },
  getArticle ({ commit, rootState }, params) {
    return new Promise((resolve, reject) => {
      this.$axios
        .get(`kb/${params.kb}/article/${params.article}`)
        .then((response) => {
          if (response) {
            commit('articles/setCurrent', response.data.data, { root: true })
            resolve(response.data.data)
          }
        }, (error) => {
          const response = error.response.data
          console.log(response)
          reject(response)
        })
    })
  },
  getModalData ({ commit }) {
    return new Promise((resolve, reject) => {
      this.$axios.get('view-modal-data').then((response) => {
        if (response) {
          commit('modal/setModalData', response.data.data, { root: true })
          resolve(response)
        }
      }, (error) => {
        const response = error.response.data
        reject(response)
      })
    })
  }
}
