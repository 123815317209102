//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BaseInputWithIcon',
  props: {
    propSearchFieldBackgroundColor: {
      type: String,
      default () {
        return ''
      }
    }
  },
  data () {
    return {
      article: ''
    }
  }
}
