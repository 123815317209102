export default function ({ $axios, store, error }) {
  $axios.onRequest(() => {
    store.commit('loading/setStatus', true)
    store.commit('ui/setDisable', true)
  })

  $axios.onResponse(() => {
    store.commit('loading/setStatus', false)
    store.commit('ui/setDisable', false)
  })

  $axios.onResponseError((erro) => {
    error({
      statusCode: erro.response.status,
      message: erro.response.data.message
    })
    store.commit('loading/setStatus', false)
    store.commit('response/setResponseError', true)
    store.commit('ui/setDisable', false)
    return Promise.resolve(false)
  })
}
