//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BreadCrumb',
  props: {
    propBreadCrumb: {
      type: Array,
      default () {
        return []
      }
    },
    propBreadCrumbBackgroundColor: {
      type: String,
      default () {
        return ''
      }
    },
    propBreadCrumbTextColor: {
      type: String,
      default () {
        return ''
      }
    }
  },
  methods: {
    lastItem (item) {
      return this.propBreadCrumb[this.propBreadCrumb.length - 1] === item
    }
  }
}
