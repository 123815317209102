//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TheSidebar',
  components: {
    BaseTreeView: () => import('@/components/tree/BaseTreeView')
  },
  props: {
    propShowTree: {
      type: Boolean,
      default: true
    },
    propHasBreadCrumb: {
      type: Boolean,
      default: false
    },
    propTreeNavActiveColor: {
      type: String,
      default: ''
    },
    propTreeNavHoverColor: {
      type: String,
      default: ''
    },
    propTreeNavHoverBackgroundColor: {
      type: String,
      default: ''
    }
  },
  computed: {
    responsiveAside () {
      const classes = this.propHasBreadCrumb ? 'top-28 md:top-44' : 'top-16 md:top-32'
      return classes.concat(this.propShowTree ? ' inset-x-0 bottom-16 md:w-80' : ' md:w-28')
    },
    articlesTree () {
      return this.$store.state.articles.tree
    }
  },
  methods: {
    onClickNodeTitle (node) {
      const articleSlug = node.slug
      this.$router.push(`/${articleSlug}`)
    }
  }
}
