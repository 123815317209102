//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    BaseOverlay: () => import('@/components/base/BaseOverlay')
  }
}
