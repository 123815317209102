export const BaseButton = () => import('../../components/base/BaseButton.vue' /* webpackChunkName: "components/base-button" */).then(c => wrapFunctional(c.default || c))
export const BaseInputWithIcon = () => import('../../components/base/BaseInputWithIcon.vue' /* webpackChunkName: "components/base-input-with-icon" */).then(c => wrapFunctional(c.default || c))
export const BaseLoader = () => import('../../components/base/BaseLoader.vue' /* webpackChunkName: "components/base-loader" */).then(c => wrapFunctional(c.default || c))
export const BaseModal = () => import('../../components/base/BaseModal.vue' /* webpackChunkName: "components/base-modal" */).then(c => wrapFunctional(c.default || c))
export const BaseOverlay = () => import('../../components/base/BaseOverlay.vue' /* webpackChunkName: "components/base-overlay" */).then(c => wrapFunctional(c.default || c))
export const BasePagination = () => import('../../components/base/BasePagination.vue' /* webpackChunkName: "components/base-pagination" */).then(c => wrapFunctional(c.default || c))
export const BreadCrumb = () => import('../../components/layout/BreadCrumb.vue' /* webpackChunkName: "components/bread-crumb" */).then(c => wrapFunctional(c.default || c))
export const MobileSearch = () => import('../../components/layout/MobileSearch.vue' /* webpackChunkName: "components/mobile-search" */).then(c => wrapFunctional(c.default || c))
export const SearchPage = () => import('../../components/layout/SearchPage.vue' /* webpackChunkName: "components/search-page" */).then(c => wrapFunctional(c.default || c))
export const TheFooter = () => import('../../components/layout/TheFooter.vue' /* webpackChunkName: "components/the-footer" */).then(c => wrapFunctional(c.default || c))
export const TheHeader = () => import('../../components/layout/TheHeader.vue' /* webpackChunkName: "components/the-header" */).then(c => wrapFunctional(c.default || c))
export const TheSidebar = () => import('../../components/layout/TheSidebar.vue' /* webpackChunkName: "components/the-sidebar" */).then(c => wrapFunctional(c.default || c))
export const TreeBaseNode = () => import('../../components/tree/BaseNode.vue' /* webpackChunkName: "components/tree-base-node" */).then(c => wrapFunctional(c.default || c))
export const TreeBaseTreeView = () => import('../../components/tree/BaseTreeView.vue' /* webpackChunkName: "components/tree-base-tree-view" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
