//
//
//
//
//
//

export default {
  name: 'BaseOverlay',
  props: {
    propBgColor: {
      type: String,
      default: 'bg-purple-300'
    },
    propOpacity: {
      type: String,
      default: 'opacity-90'
    },
    propZIndex: {
      type: String,
      default: 'z-50'
    },
    propOthersClasses: {
      type: String,
      default: ''
    },
    propPosition: {
      type: String,
      default: 'fixed'
    }
  }
}
