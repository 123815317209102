import { render, staticRenderFns } from "./default.vue?vue&type=template&id=63cc07dd&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseButton: require('/home/forge/qa-view.docmaker.io/releases/20240913161405/components/base/BaseButton.vue').default,BaseModal: require('/home/forge/qa-view.docmaker.io/releases/20240913161405/components/base/BaseModal.vue').default,TheHeader: require('/home/forge/qa-view.docmaker.io/releases/20240913161405/components/layout/TheHeader.vue').default,BreadCrumb: require('/home/forge/qa-view.docmaker.io/releases/20240913161405/components/layout/BreadCrumb.vue').default,TheSidebar: require('/home/forge/qa-view.docmaker.io/releases/20240913161405/components/layout/TheSidebar.vue').default,TheFooter: require('/home/forge/qa-view.docmaker.io/releases/20240913161405/components/layout/TheFooter.vue').default})
