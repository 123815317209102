export default () => ({
  data: {
    url: '',
    name: '',
    description: '',
    logo: '',
    slug: '',
    uuid: ''
  }
})
