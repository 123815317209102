//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'DefaultLayout',
  components: {
    TheHeader: () => import('@/components/layout/TheHeader'),
    TheSidebar: () => import('@/components/layout/TheSidebar'),
    TheFooter: () => import('@/components/layout/TheFooter'),
    BreadCrumb: () => import('@/components/layout/BreadCrumb'),
    BaseModal: () => import('@/components/base/BaseModal'),
    BaseButton: () => import('@/components/base/BaseButton')
  },
  data () {
    return {
      showMobileSearch: false,
      showTree: true,
      loaded: false
    }
  },
  computed: {
    responseError () {
      return this.$store.state.response.responseError
    },
    responsiveContent () {
      const classes = this.hasBreadcrumb ? 'top-40 md:top-44' : 'top-28 md:top-32'
      return classes.concat(this.showTree ? ' md:left-80' : ' md:left-28')
    },
    breadcrumb () {
      return this.$store.state.articles.current.breadcrumb
    },
    hasBreadcrumb () {
      return this.breadcrumb.length > 0
    },
    pageBackgroundColor () {
      return this.getConfigurationColorValue('kb-styling-page-background-color')
    },
    headerBackgroundColor () {
      return this.getConfigurationColorValue('kb-styling-header-background-color')
    },
    breadCrumbBackgroundColor () {
      return this.getConfigurationColorValue('kb-styling-breadcrumb-background-color')
    },
    breadCrumbTextColor () {
      return this.getConfigurationColorValue('kb-styling-breadcrumb-text-color')
    },
    searchFieldBackgroundColor () {
      return this.getConfigurationColorValue('kb-styling-search-field-background-color')
    },
    treeNavActiveColor () {
      return this.getConfigurationColorValue('kb-styling-tree-nav-active-color')
    },
    treeNavHoverColor () {
      return this.getConfigurationColorValue('kb-styling-tree-nav-hover-color')
    },
    treeNavHoverBackgroundColor () {
      return this.getConfigurationColorValue('kb-styling-tree-nav-hover-background-color')
    },
    contenTextColor () {
      return this.getConfigurationColorValue('kb-styling-content-text-color')
    },
    homePage () {
      const location = window.location
      const prefix = location.href.split(':')[0]
      return `${prefix}://${location.hostname}`
    }
  },
  async beforeMount () {
    const url = window.location.hostname
    const urlArray = url.split('.')
    if (urlArray.length > 3) {
      await this.$store.dispatch('api/getModalData')
      await this.$store.dispatch('api/getKb', urlArray[0])
      await this.$store.dispatch('api/getTree')
      this.loaded = true
    }
  },
  methods: {
    async searchTerm (term) {
      await this.$store.dispatch('api/searchArticle', { search: term, page: 1 })
      this.$router.push(`/?search=${term}&page=1`)
      this.showMobileSearch = false
    },
    getConfigurationColorValue (key) {
      if (this.$store.state.kb.data.kb_configurations) {
        const configuration = this.$store.state.kb.data.kb_configurations.find(config => config.key === key)
        return configuration ? configuration.pivot.value : undefined
      }
    },
    modalButtonClick () {
      window.location.replace(this.homePage)
    }
  }
}
